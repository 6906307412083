import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/consolePage/Login';
import HomePage from './pages/HomePage';
import Products from './pages/Products'; // Import other protected pages
import ProtectedRoute from './pages/consolePage/ProtectedRoute';

function App() {
  return (
    <Routes>
      <Route
        path="/login"
        element={<ProtectedRoute element={<Login />} authCheck={true} />}
      />
      <Route
        path="/home"
        element={<ProtectedRoute element={<HomePage />} />}
      />
      <Route
        path="/products"
        element={<ProtectedRoute element={<Products />} />}
      />
      {/* Add more protected routes as needed */}
      <Route path="/" element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default App;
