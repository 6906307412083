import React, { useState } from 'react';
import { useForm } from "react-hook-form";

const AddProduct = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        console.log(data);
        setLoading(true);
        try {
            const response = await fetch("https://wayawaya-db.com/api/addProduct", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                alert("Product added successfully!");
                reset(); // Reset the form after successful submission
            } else {
                alert("Failed to add product. Please try again.");
            }
        } catch (error) {
            console.log("Error sending data:", error);
            alert("An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center py-12 bg-gray-100 ">
            <div className="bg-white shadow-md rounded-lg p-8 w-full max-w-md">
                <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">Add Product</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-5">
                        <label htmlFor="productName" className="block mb-2 text-sm font-medium text-gray-900">
                            Product Name *
                        </label>
                        <input
                            type="text"
                            id="productName"
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Product Name"
                            {...register("productName", { required: true })}
                        />
                        {errors.productName && <p className="text-red-600 text-sm mt-1">This field is required</p>}
                    </div>
                    <div className="mb-5">
                        <label htmlFor="productType" className="block mb-2 text-sm font-medium text-gray-900">
                            Product Type *
                        </label>
                        <input
                            type="text"
                            id="productType"
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Product Type"
                            {...register("productType", { required: true })}
                        />
                        {errors.productType && <p className="text-red-600 text-sm mt-1">This field is required</p>}
                    </div>
                    <div className="mb-5">
                        <label htmlFor="productQuantity" className="block mb-2 text-sm font-medium text-gray-900">
                            Product Quantity
                        </label>
                        <input
                            type="number"
                            id="productQuantity"
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Product Quantity"
                            {...register("productQuantity")}
                        />
                        {errors.productQuantity && <p className="text-red-600 text-sm mt-1">This field is required</p>}
                    </div>
                    <div className="mb-5">
                        <label htmlFor="productPrice" className="block mb-2 text-sm font-medium text-gray-900">
                            Product Price *
                        </label>
                        <div className="flex items-center">
                            <input
                                type="text"
                                id="productPrice"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-l-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Product Price"
                                {...register("productPrice", { required: true })}
                            />
                        </div>
                        {errors.productPrice && <p className="text-red-600 text-sm mt-1">This field is required</p>}
                    </div>
                    <button
                        type="submit"
                        className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        {loading ? "Loading..." : "Add Product"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AddProduct;
