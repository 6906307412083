import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element, redirectPath = '/login', authCheck }) => {
  const isAuthenticated = document.cookie.includes('token'); // Check for token in cookies
  // const isAuthenticated = true;

  if (authCheck) {
    // If authCheck is true, redirect authenticated users away from the login page
    return isAuthenticated ? <Navigate to="/home" /> : element;
  }

  // For protected routes (Home, Products, etc.)
  return isAuthenticated ? element : <Navigate to={redirectPath} />;
};

export default ProtectedRoute;
