import React, { useState } from "react";
import { useForm } from "react-hook-form";

const SignUp = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data) => {
    console.log(data)
    if (data.password !== data.confrimPass) {
      alert("Passwords do not match");
      return;
    }

    setLoading(true);

    try {
      const response = await fetch("https://wayawaya-db.com/api/signUp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setSuccess(true);
        setTimeout(() => {
          setLoading(false);
          reset();
        }, 3000); // Simulate redirection or other success action
      } else {
        const errorMessage = await response.json();
        alert(errorMessage.message || "The username or email already exists. Please try to log in.");
        setSuccess(false);
        setLoading(false);
        reset();
      }
    } catch (error) {
      alert("An error occurred: " + error.message);
      setSuccess(false);
      setLoading(false);
      reset();
    }
  };

  return (
    <div className="py-12 flex justify-center items-center bg-gray-100">
      {success ? (
        <div className="text-lg font-bold text-green-600">Sign Up was successful! Redirecting...</div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col  rounded-lg bg-white p-6 shadow-lg w-full max-w-md"
        >
          <h1 className="text-3xl font-semibold mb-6 text-center">Add new user</h1>

          {/* USERNAME INPUT */}
          <label htmlFor="userName" className="mb-1 font-medium">
            Username
          </label>
          <input
            type="text"
            id="userName"
            placeholder="Enter your username"
            className="mb-4 p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            {...register("userName", { required: "Username is required" })}
          />
          {errors.userName && <p className="text-red-600 mb-4">{errors.userName.message}</p>}

          {/* PASSWORD INPUT */}
          <label htmlFor="password" className="mb-1 font-medium">
            Password
          </label>
          <input
            type="password"
            id="password"
            placeholder="Enter your password"
            className="mb-4 p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            {...register("password", { required: "Password is required" })}
          />
          {errors.password && <p className="text-red-600 mb-4">{errors.password.message}</p>}

          {/* CONFIRM PASSWORD INPUT */}
          <label htmlFor="confirmPass" className="mb-1 font-medium">
            Confirm Password
          </label>
          <input
            type="password"
            id="confirmPass"
            placeholder="Confirm your password"
            className="mb-4 p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            {...register("confrimPass", { required: "Please confirm your password" })}
          />
          {errors.confrimPass && <p className="text-red-600 mb-4">{errors.confrimPass.message}</p>}

          {/* USER TYPE SELECTION */}
          <label htmlFor="userType" className="mb-1 font-medium">
            User Type
          </label>
          <select
            id="userType"
            className="mb-4 p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            {...register("userType", { required: "User type is required" })}
          >
            <option value="">Select user type</option>
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
          {errors.userType && <p className="text-red-600 mb-4">{errors.userType.message}</p>}

          <button
            type="submit"
            className={`w-full py-2 text-white rounded-lg ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500'}`}
            disabled={loading}
          >
            {loading ? "Loading..." : "Sign Up"}
          </button>
        </form>
      )}
    </div>
  );
};

export default SignUp;
