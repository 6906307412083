import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { FaSortAmountDownAlt } from "react-icons/fa";
import { FaSortAmountUp } from "react-icons/fa";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";


const Records = () => {
  const [orders, setOrders] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // Track sorting order: "asc" or "desc"
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const recordsPerPage = 10; // Number of records per page

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch("https://wayawaya-db.com/api/get-orders");
        const result = await response.json();
        setOrders(result);
        console.log(result);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);

  const filterOrdersByDate = () => {
    const start = new Date(startDate).setHours(0, 0, 0, 0);
    const end = new Date(endDate).setHours(23, 59, 59, 999);

    return orders.filter(order => {
      const orderDate = new Date(order.order_date).getTime();
      return (!startDate || orderDate >= start) && (!endDate || orderDate <= end);
    });
  };

  const sortOrdersByDate = (orders) => {
    return [...orders].sort((a, b) => {
      const dateA = new Date(a.order_date).getTime();
      const dateB = new Date(b.order_date).getTime();
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
  };

    //fix the fucking time 
    function formatDateToReadableFormat(dateString) {
      const date = new Date(dateString);
      
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    

  const downloadExcel = () => {
    const filteredOrders = filterOrdersByDate();

    const worksheet = XLSX.utils.json_to_sheet(
      filteredOrders.map(order => ({
        id: order.id,
        total_amount: Number(order.total_amount).toFixed(2),
        order_date:formatDateToReadableFormat(order.order_date),
        order_items: JSON.parse(order.order_items).map(item =>
          `${item.product_name} x ${item.quantity} (${item.product_price * item.quantity}Kč)`
        ).join(', ')
      }))
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Filtered Orders");
    XLSX.writeFile(workbook, "Filtered_Orders.xlsx");
  };

  const handleSortToggle = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filteredOrders = filterOrdersByDate();
  const sortedOrders = sortOrdersByDate(filteredOrders);

  // Pagination logic
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = sortedOrders.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(sortedOrders.length / recordsPerPage);

  return (
    <div className='p-8'>
      <h1 className='text-2xl font-bold mb-4'>Order Records</h1>

      <div className='mb-4 flex justify-between gap-4'>
        <div className='flex gap-4'>
          <div>
            <label htmlFor="startDate" className='block mb-2'>Start Date:</label>
            <input
              type="date"
              id="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className='border px-2 py-1 rounded'
            />
          </div>

          <div>
            <label htmlFor="endDate" className='block mb-2'>End Date:</label>
            <input
              type="date"
              id="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className='border px-2 py-1 rounded'
            />
          </div>

        </div>

        <div className='flex gap-2'>
          <button
            onClick={handleSortToggle}
            className='mb-4 px-4 py-2 h-[2rem] bg-gray-500 text-white rounded flex items-center gap-2'
          >
            Sort by Date
            {sortOrder === "asc" ? (
              <FaSortAmountDownAlt />
            ) : (
              <FaSortAmountUp />
            )}
          </button>
          <button
            onClick={downloadExcel}
            className='mb-4 px-4 bg-gray-500 text-white h-8 rounded'
          >
            Export
          </button>
        </div>

      </div>
      {orders.length === 0 ? (
        <p>No orders available.</p>
      ) : (
        <>
          <table className='w-full border-collapse border'>
            <thead>
              <tr>
                <th className='border px-4 py-2'>Order ID</th>
                <th className='border px-4 py-2'>Order Items</th>
                <th className='border px-4 py-2'>Total Amount</th>
                <th className='border px-4 py-2'>Order Date</th>
                <th className='border px-4 py-2'>Payment Method</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((order) => (
                <tr key={order.id}>
                  <td className='border px-4 py-2'>{order.id}</td>
                  <td className='flex border px-4 py-2'>
                    {JSON.parse(order.order_items).map((item, index) => (
                      <div key={index}>
                        {item.product_name} x {item.quantity} ({item.product_price * item.quantity} Kč) ,
                      </div>
                    ))}
                  </td>
                  <td className='border px-4 py-2'>
                    {Number(order.total_amount).toFixed(2)}Kč
                  </td>
                  <td className='border px-4 py-2'>
                  {formatDateToReadableFormat(order.order_date)}


                  </td>
                  <td className='border px-4 py-2'>
                    {order.payment_method}
                  </td>

                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <div className='flex justify-between items-center mt-6'>
            <button
              className='flex items-center gap-2 px-4 py-2 bg-gray-200 text-gray-700 rounded-md disabled:opacity-50'
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FaArrowLeft />
              Previous
            </button>
            <span className="text-sm md:text-base">Page {currentPage} of {totalPages}</span>
            <button
              className='flex items-center gap-2 px-4 py-2 bg-gray-200 text-gray-700 rounded-md disabled:opacity-50'
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
              <FaArrowRight />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Records;
