// src/pages/consolePage/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../content/waya-logo.png';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await fetch('https://wayawaya-db.com/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const { token, userType } = await response.json();
        console.log(response)
        const expires = new Date(Date.now() + 24 * 60 * 60 * 1000).toUTCString(); // 24 hours
        document.cookie = `token=${token}; expires=${expires}; path=/`;
        localStorage.setItem('username', username);
        localStorage.setItem('userType', userType);

        navigate('/home'); // Redirect to the main application page
      } else {
        const { message } = await response.json();
        setError(message || 'Login failed');
      }
    } catch (error) {
      setError('An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-screen flex flex-col justify-center items-center gap-4">
      <div>
          <div className='flex justify-center items-center w-[12rem] h-[12rem] border-8 border-gray-600/30 rounded-full mb-8'>
            <img className='p-5' src={logo} alt='Waya Maha' />
        </div>
      </div>
      <form
        onSubmit={handleLogin}
        className="flex flex-col justify-center border-8 border-gray-600/30 rounded-2xl lg:w-[30rem] lg:h-[30rem] p-6"
      >
        <h1 className="text-3xl font-bold mb-10 text-center">Login</h1>
        <label htmlFor="username" className="mb-2">Username</label>
        <input
          type="text"
          id="username"
          placeholder="Enter your username"
          value={username}
          onChange={({ target: { value } }) => setUsername(value)}
          className="mb-4 p-2 border rounded"
        />
        <label htmlFor="password" className="mb-2">Password</label>
        <input
          type="password"
          id="password"
          placeholder="Enter your password"
          value={password}
          onChange={({ target: { value } }) => setPassword(value)}
          className="mb-4 p-2 border rounded"
        />
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded mt-8"
          disabled={loading}
        >
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
    </div>
  );
};

export default Login;
