import React, { useState, useEffect } from "react";
import axios from "axios";

const Users = () => {
  const [data, setData] = useState([]);
  const [editableData, setEditableData] = useState({}); // Store editable data per user

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://wayawaya-db.com/api/get-users");
        const result = await response.json();
        setData(result);

        // Initialize editableData state
        const initialEditableData = {};
        result.forEach((user) => {
          initialEditableData[user.idusers] = {
            username: user.username,
            password: "", // Default empty password
          };
        });
        setEditableData(initialEditableData);
      } catch (error) {
        console.log("ERROR FETCHING DATA", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (id, field, value) => {
    setEditableData((prevData) => ({
      ...prevData,
      [id]: {
        ...prevData[id],
        [field]: value,
      },
    }));
  };

  const updateUser = (id) => {
    const { username, password } = editableData[id];
    const updates = {};

    if (username) updates.username = username;
    if (password) {
      if (password.length < 6) {
        alert("Password must be at least 6 characters long");
        return;
      }
      updates.password = password;
    }

    axios
      .put(`https://wayawaya-db.com/update/${id}`, updates)
      .then((response) => {
        alert("User updated successfully");
        // Refresh the data after updating
        setData((prevData) =>
          prevData.map((item) =>
            item.idusers === id
              ? { ...item, username: updates.username || item.username }
              : item
          )
        );
        // Clear password input field after update
        setEditableData((prevData) => ({
          ...prevData,
          [id]: {
            ...prevData[id],
            password: "",
          },
        }));
      })
      .catch((error) => {
        console.error("There was an error updating the user!", error);
      });
  };

  const deleteUser = (id) => {
    // Get the username of the user to be deleted
    const user = data.find((item) => item.idusers === id);

    // Confirm deletion
    const confirmed = window.confirm(`Are you sure you want to delete the user "${user?.username}"? This action cannot be undone.`);

    if (!confirmed) {
      return; // Exit if the user cancels the deletion
    }

    axios
      .delete(`https://wayawaya-db.com/delete/${id}`)
      .then((response) => {
        alert("User deleted successfully");
        // Filter out the deleted user
        setData(data.filter((item) => item.idusers !== id));
        // Remove user from editableData
        setEditableData((prevData) => {
          const newEditableData = { ...prevData };
          delete newEditableData[id];
          return newEditableData;
        });
      })
      .catch((error) => {
        console.error("There was an error deleting the user!", error);
      });
  };

  return (
    <div className="p-8 bg-gray-100">
      <h1 className="text-2xl font-bold mb-10">Account Management</h1>
      <div className="lg:grid lg:grid-cols-4 lg:justify-center lg:gap-8">

        {data.map((item) => (

          <div
            key={item.idusers}
            className="lg:w-auto bg-white shadow-md rounded-lg p-4 mb-4 flex flex-col items-start"
          >
            <div className="w-full gap-4 flex justify-between mb-4">
              <div className="text-lg font-semibold">{item.username}</div>
              <input
                type="text"
                value={editableData[item.idusers]?.username || ""}
                onChange={(e) => handleChange(item.idusers, "username", e.target.value)}
                placeholder="Enter new username"
                className="border px-3 py-2 rounded "
              />
            </div>
            <div className="w-full gap-4 flex justify-between">

              <div className="text-gray-600 font-semibold mt-1 mr-5">ID: {item.idusers}</div>
              <input
                type="password"
                
                value={editableData[item.idusers]?.password || ""}
                onChange={(e) => handleChange(item.idusers, "password", e.target.value)}
                placeholder="Enter new password"
                className="border px-3 py-2 rounded"
              />
            </div>
            <div className="w-full mt-4 flex justify-end space-x-4">
              <button
                onClick={() => updateUser(item.idusers)}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Update
              </button>
              <button
                onClick={() => deleteUser(item.idusers)}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>

        ))}
      </div>

    </div>
  );
};

export default Users;
