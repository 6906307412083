import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const ITEMS_PER_PAGE = 10; // Number of products to display per page

const Products = () => {
  const [data, setData] = useState([]);
  const [editMode, setEditMode] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedType, setSelectedType] = useState(""); // For filtering by product type
  const [currentPage, setCurrentPage] = useState(1); // Track current page

  useEffect(() => {
    fetchProducts(); // Fetch products on component mount
  }, [searchQuery, selectedType]); // Update products list when search or filter changes

  const fetchProducts = async () => {
    try {
      const response = await fetch(`https://wayawaya-db.com/api/get-products?search=${searchQuery}&type=${selectedType}`);
      const result = await response.json();
      setData(result);
      setCurrentPage(1); // Reset to first page whenever products are fetched
    } catch (error) {
      console.log("ERROR FETCHING DATA", error);
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedData = [...data];

    // Handle empty or 0 values for numeric fields
    if (field === 'product_price' || field === 'product_qun') {
      updatedData[index][field] = value === '' ? '' : parseFloat(value) || 0;
    } else {
      updatedData[index][field] = value;
    }
    setData(updatedData);
  };

  const toggleEditMode = (globalIndex) => {
    setEditMode(editMode === globalIndex ? null : globalIndex);
  };

  const handleSave = async (globalIndex) => {
    const product = data[globalIndex];

    try {
      const response = await fetch(`https://wayawaya-db.com/api/update-product/${product.idproducts}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product_name: product.product_name,
          product_price: product.product_price,
          product_qun: product.product_qun || 0, // Default to 0 if empty
          product_type: product.product_type,
        }),
      });

      if (response.ok) {
        fetchProducts(); // Refetch the products list after successful update
        toggleEditMode(null); // Exit edit mode
      } else {
        console.error('Failed to update product');
      }
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  const handleDelete = async (globalIndex) => {
    const product = data[globalIndex];
    // Confirm deletion
    const confirmed = window.confirm(`Are you sure you want to delete the product "${product.product_name}"? This action cannot be undone.`);

    if (!confirmed) {
      return; // Exit if the user cancels the deletion
    }


    try {
      const response = await fetch(`https://wayawaya-db.com/api/delete-product/${product.idproducts}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        fetchProducts(); // Refetch the products list after successful delete
      } else {
        console.error('Failed to delete product');
      }
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  // Get unique product types for the dropdown
  const productTypes = [...new Set(data.map(item => item.product_type))];

  // Filtered data based on search and selected type, before pagination
  const filteredData = data.filter((item) =>
    item.product_name.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (selectedType === "" || item.product_type === selectedType)
  );

  // Pagination logic
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedData = filteredData.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className='p-4 md:p-8'>
      
      <div className='text-xl md:text-2xl font-bold mb-4'>Products</div>

      <input
        type="text"
        placeholder="Search for a product..."
        className="mb-4 p-2 w-full md:w-2/5 border border-black rounded-lg"
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
      />

      <div className="relative inline-block mx-0 md:mx-5 mb-4 w-full md:w-auto">
        <select
          className="appearance-none p-2 pr-10 border border-black rounded-lg w-full"
          value={selectedType}
          onChange={(e) => {
            setSelectedType(e.target.value);
          }}
        >
          <option value="">All Types</option>
          {productTypes.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center px-4 pointer-events-none">
          <svg className="w-4 h-4 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </div>
      </div>

      <div className='grid grid-cols-2 md:grid-cols-5 gap-4 mt-4 font-bold border-b pb-2'>
        <div>Product Name</div>
        <div className='hidden md:block'>Price (Kč)</div>
        <div className='hidden md:block'>Quantity</div>
        <div className='hidden md:block'>Type</div>
        <div className='hidden lg:flex lg:justify-end md:block'>Actions</div>
      </div>

      {paginatedData.length > 0 ? (
        paginatedData.map((item, index) => {
          // Find the global index of the item in the original data
          const globalIndex = data.findIndex(product => product.idproducts === item.idproducts);

          return (
            <div key={item.idproducts} className='grid grid-cols-2 md:grid-cols-5 gap-4 items-center py-2 border-b'>
              <div>
                {editMode === globalIndex ? (
                  <input
                    type="text"
                    value={item.product_name}
                    onChange={(e) => handleInputChange(globalIndex, 'product_name', e.target.value)}
                    className='border p-1 rounded w-full'
                  />
                ) : (
                  <span>{item.product_name}</span>
                )}
              </div>
              <div className='hidden md:block'>
                {editMode === globalIndex ? (
                  <input
                    type="number"
                    value={isNaN(parseFloat(item.product_price)) ? '' : item.product_price}  // Handle invalid value
                    onChange={(e) => handleInputChange(globalIndex, 'product_price', e.target.value)}
                    className='border p-1 rounded w-full'
                  />
                ) : (
                  <span>{parseFloat(item.product_price).toFixed(2)}</span>
                )}
              </div>
              <div className='hidden md:block'>
                {editMode === globalIndex ? (
                  <input
                    type="number"
                    value={isNaN(parseFloat(item.product_qun)) || item.product_qun === 0 ? '' : item.product_qun}  // Handle invalid value
                    onChange={(e) => handleInputChange(globalIndex, 'product_qun', e.target.value)}
                    className='border p-1 rounded w-full'
                    min="0"
                  />
                ) : (
                  <span>{item.product_qun}</span>
                )}
              </div>
              <div className='hidden md:block'>
                {editMode === globalIndex ? (
                  <input
                    type="text"
                    value={item.product_type}
                    onChange={(e) => handleInputChange(globalIndex, 'product_type', e.target.value)}
                    className='border p-1 rounded w-full'
                  />
                ) : (
                  <span>{item.product_type}</span>
                )}
              </div>
              <div className='flex justify-end gap-2'>
                {editMode === globalIndex ? (
                  <button
                    onClick={() => handleSave(globalIndex)}
                    className='bg-green-500 text-white px-4 py-2 rounded'
                  >
                    Save
                  </button>
                ) : (
                  <button
                    onClick={() => toggleEditMode(globalIndex)}
                    className='bg-blue-500 text-white px-4 py-2 rounded'
                  >
                    Edit
                  </button>
                )}
                <button
                  onClick={() => handleDelete(globalIndex)}
                  className='bg-red-500 text-white px-4 py-2 rounded'
                >
                  Delete
                </button>
              </div>
            </div>
          );
        })
      ) : (
        <div className="text-center col-span-6 py-4">No products found</div>
      )}

      {/* Pagination Controls */}
      <div className='flex justify-between items-center mt-6'>
        <button
          className='flex items-center gap-2 px-4 py-2 bg-gray-200 text-gray-700 rounded-md disabled:opacity-50'
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <FaArrowLeft />
          Previous
        </button>
        <span className="text-sm md:text-base">Page {currentPage} of {totalPages}</span>
        <button
          className='flex items-center gap-2 px-4 py-2 bg-gray-200 text-gray-700 rounded-md disabled:opacity-50'
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
          <FaArrowRight />
        </button>
      </div>
    </div>
  );
};

export default Products;
