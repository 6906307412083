import React from 'react';
import { FaHome } from "react-icons/fa";
import { MdProductionQuantityLimits } from "react-icons/md";
import { FiDatabase } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa";
import { TiFolderAdd } from "react-icons/ti";
import { RiUserAddLine } from "react-icons/ri";
import { AiOutlineLogout } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { RxExit } from "react-icons/rx";


const NavBar = ({ pageSelected, setPageSelected }) => {
    const navigate = useNavigate(); // Hook to navigate programmatically
    const username = localStorage.getItem('username');
    const userType = localStorage.getItem('userType'); // Get user type

    const handleLogout = () => {
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"; // Clear the token cookie
        navigate('/login'); // Redirect to login page
    };

    return (
        <div className='border-4 w-auto rounded-lg mb-4 lg:flex justify-between lg:px-10'>
            <div className='lg:flex justify-center p-4 gap-8'>
                <div className='flex items-center'>
                    <FaHome className='mr-2 lg:w-[2rem] lg:h-[2rem]' />
                    <button onClick={() => setPageSelected("Home")} className='font-bold lg:text-xl hover:text-cyan-700'>Home</button>
                </div>
                <div className='flex items-center'>
                    <MdProductionQuantityLimits className='mr-2 lg:w-[2rem] lg:h-[2rem]' />
                    <button onClick={() => setPageSelected("Products")} className='font-bold lg:text-xl hover:text-cyan-700'>Products</button>
                </div>
                <div className='flex items-center'>
                    <FiDatabase className='mr-2 lg:w-[2rem] lg:h-[2rem]' />
                    <button
                        onClick={() => setPageSelected("Records")}
                        className={`font-bold lg:text-xl hover:text-cyan-700 ${userType === 'user' ? 'cursor-not-allowed text-gray-500' : ''}`}
                        disabled={userType === 'user'}
                    >
                        Record
                    </button>
                </div>
                <div className='flex items-center'>
                    <FaRegUser className='mr-2 lg:w-[2rem] lg:h-[2rem]' />
                    <button onClick={() => setPageSelected("Users")} className={`font-bold lg:text-xl hover:text-cyan-700 ${userType === 'user' ? 'cursor-not-allowed text-gray-500' : ''}`} disabled={userType === 'user'}>Users</button>
                </div>
                <div className='flex items-center'>
                    <TiFolderAdd className='mr-2 lg:w-[2rem] lg:h-[2rem]' />
                    <button onClick={() => setPageSelected("add-product")} className='font-bold lg:text-xl hover:text-cyan-700'>Add Product</button>
                </div>
                <div className='flex items-center'>
                    <RiUserAddLine className='mr-2 lg:w-[2rem] lg:h-[2rem]' />
                    <button
                        onClick={() => setPageSelected("add-user")}
                        className={`font-bold lg:text-xl hover:text-cyan-700 ${userType === 'user' ? 'cursor-not-allowed text-gray-500' : ''}`}
                        disabled={userType === 'user'}
                    >
                        Add User
                    </button>
                </div>
            </div>

            <div className='flex items-center'>

                {/* Logout Button */}
                <div className='flex items-center'>
                    {/* <AiOutlineLogout className='text-red-700 mr-2 lg:w-[2rem] lg:h-[2rem]' /> */}
                    <RxExit className='text-red-700 mr-2 lg:w-[1.4rem] lg:h-[1.4rem]' />
                </div>

                <button
                    onClick={handleLogout}
                    className='font-bold text-red-700 lg:text-xl'
                >
                    Logout
                </button>

                {/* Username display */}
                <div className='flex items-center text-cyan-500 font-bold lg:text-md ml-8 '>user: {username}</div>
            </div>
        </div>
    );
};

export default NavBar;
