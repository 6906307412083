import React, { useState } from 'react';
import { IoNotificationsCircleSharp } from "react-icons/io5";

const NotificationButton = ({ data }) => {
    const [open, isOpen] = useState(false);

    const hasNotifications = data && data.length > 0;
    console.log(data);


    return (
        <div className='relative m-4'>
            {/* Notification Icon */}
            <div className='flex justify-end mx-4 items-center cursor-pointer'>
                <IoNotificationsCircleSharp
                    className={`w-[50px] h-[50px] rounded-full ${hasNotifications ? 'text-red-400' : 'text-green-400'}`}
                    onClick={() => isOpen(true)}
                />
                
                {/* Notification Badge */}
                {hasNotifications && (
                    <span className="absolute -top-[-0.2rem] -right-1 bg-red-600 text-white text-xs font-bold px-1.5 py-0.5 rounded-full">
                        +{data.length}
                    </span>
                )}
            </div>

            {/* PopUp Message */}
            {open && (
                <div className="fixed inset-0 flex items-center justify-center pt-40 p-4 bg-black bg-opacity-50">
                    <div className="bg-white text-white p-6 w-[30rem] max-h-[90vh] rounded-xl">
                        <div className="flex flex-col h-[30rem]">
                            {/* Title */}
                            <h3 className="text-xl text-black font-bold mb-4">Out of Stock Items</h3>

                            {/* Scrollable List */}
                            <div className="flex-1 overflow-y-auto">
                                <ul className="list-disc text-left mb-4 space-y-2 text-black">
                                    {data.filter(item => item.product_qun === '0').map((item, index) => (
                                        <li key={index}>{item.product_name}</li>
                                    ))}
                                </ul>
                            </div>

                            {/* Close Button */}
                            <button
                                onClick={() => isOpen(false)}
                                className="mt-4 px-4 py-2 bg-sky-400 text-red-600 rounded"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NotificationButton;
