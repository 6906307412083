import React, { useState } from 'react';
import Products from './Products';
import Records from './Records';
import Users from './Users';
import AddProduct from './consolePage/AddProduct';
import AddUser from './consolePage/AddUser';
import NavBar from './NavBar';
import MainPage from './MainPage';

const HomePage = () => {
    const [pageSelected, setPageSelected] = useState("Home");
    return (
        <div className='flex flex-col h-auto m-10'>

            <NavBar pageSelected={pageSelected} setPageSelected={setPageSelected} />
            <div className='border-4 rounded-xl w-full border-gray-300'>
                {pageSelected === "Home" && <MainPage />}
                {pageSelected === "Products" && <Products />}
                {pageSelected === "Records" && <Records />}
                {pageSelected === "Users" && <Users />}
                {pageSelected === "add-product" && <AddProduct />}
                {pageSelected === "add-user" && <AddUser />}
            </div>


        </div>
    );
};

export default HomePage;
